module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Unroll Digital","short_name":"Unroll Digital","start_url":"/","background_color":"#3182ce","theme_color":"#3182ce","display":"minimal-ui","icon":"src/images/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-159092148-1"],"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
